

























































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TheMask } from 'vue-the-mask';
import { required, email, minLength } from 'vuelidate/lib/validators';
import { studentService } from '@/services';
import { Tag } from '@/types/members';
import { hsForm } from '@/components';
import MButton from '@/shared/components/MButton.vue';
import ToastHelper from '@/shared/helpers/toast';
import TrackingHelper from '@/shared/helpers/tracking';
import { sortLocaleWithKey } from '@/libs/strings';
import debounce from 'lodash.debounce';
import {
  APIExtraAttributes,
  BaseProduct,
  ProductCourse,
  ProductLibrary,
  ProductPaginatedResponse,
} from '@/types/products';

type Product = BaseProduct & ProductLibrary & ProductCourse;

const AuthModule = namespace('auth');
const ProductModule = namespace('product');
const MemberModule = namespace('member');
const SchoolModule = namespace('school');

@Component({
  components: {
    TheMask,
    hsForm,
    MButton,
  },
})
export default class ImportMembersModal extends Vue {
  @Prop({ required: true }) modalId!: string;

  @AuthModule.State loggedUser!: any;
  @MemberModule.State products!: any[];
  @SchoolModule.State selectedSchool!: any;

  @MemberModule.Action setProducts!: (products: any) => void;
  @ProductModule.Action getAllProducts!: (config: {
    params: APIExtraAttributes;
  }) => Promise<ProductPaginatedResponse<Product>>;

  members: any[] = [
    {
      id: Math.random(),
      first_name: '',
      email: '',
      tags: [],
    },
  ];
  productsModel: any[] = [];
  tagsModel: Tag[] = [];
  tagsOptions: Tag[] = [];

  isLoading: boolean = false;
  isSearching: Boolean = false;
  prefixLang: string = 'sparkmembers.members-list.import-members-modal';

  mounted() {
    this.getTags();
    this.closeModal();
    this.searchProduct = debounce(this.searchProduct, 500);
  }

  closeModal() {
    this.$root.$on('bv::modal::hide', (_: any, modalId: string) => {
      modalId === 'import-members-modal' && this.$emit('closeModal');
    });
  }

  async getProducts(search?: string) {
    try {
      this.isSearching = true;
      const params: APIExtraAttributes = [{ key: 'page', value: 1 }, { key: 'per_page', value: 50 }, { key: 'request_attributes', value: 'library_resource' },];

      if (search?.length) params.push({ key: 'title', value: search });

      const { school_products } = await this.getAllProducts({ params });
      this.setProducts(school_products);
    } finally {
      this.isSearching = false;
    }
  }

  searchProduct(search) {
    return this.getProducts(search);
  }

  async getTags() {
    try {
      const query = [
        { key: 'page', value: 1 },
        { key: 'per_page', value: 50 },
        { key: 'sort', value: 'id' },
        { key: 'direction', value: 'desc' },
      ];

      const { categories } = await studentService.getUserCategories(query);

      // this.tagsOptions = categories.sort((a, b) => a.name.localeCompare(b.name));
      this.tagsOptions = sortLocaleWithKey(categories, 'name');
    } catch (error) {
      ToastHelper.dangerMessage(this.$t(`sparkmembers.members-list.tags-modal.toast.get-tags-error`));
    }
  }

  addMember() {
    this.members.push({
      id: Math.random(),
      first_name: '',
      email: '',
      tags: [],
    });
  }

  removeMember(member) {
    this.members = this.members.filter(m => m.id !== member.id);
  }

  validations() {
    return {
      members: {
        $each: {
          first_name: {
            required,
            minLength: minLength(2),
          },
          email: {
            required,
            email,
            schoolEmail: this.validateSchoolEmail,
          },
        },
      },
      products: {
        required,
      },
    };
  }

  validateSchoolEmail(value: string) {
    return value !== this.selectedSchool.email;
  }

  async inviteMembers() {
    try {
      this.isLoading = true;
      await this.promiseMembers();

      const products_type = this.productsModel.map(product => product.kind);

      this.trackingMemberImported(this.members.length, this.productsModel.length, products_type);

      this.$emit('loadMembers');
      this.$emit('closeModal');

      ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.success`));
    } catch (error) {
      const isEmailAlreadyInUse = error.response?.data?.errors?.email[0] === 'já está em uso';
      if (isEmailAlreadyInUse) {
        ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.error-email-already-in-use`));
      } else {
        ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.error`));
      }
    } finally {
      this.isLoading = false;
    }
  }

  async promiseMembers() {
    await Promise.all(
      this.members.map(async ({ first_name, email, tags }) => {
        const payload = JSON.parse(
          JSON.stringify({
            first_name,
            email,
            category_ids: tags.map(({ id }) => id),
            type: 'Student',
            enrollments: this.formatEnrollments(),
          })
        );

        await studentService.createInvite(payload);
      })
    );
  }

  formatEnrollments() {
    return this.productsModel.map(product => ({
      enrollment: {
        school_product_id: Number(product.id),
        status: 'active',
        unlimited: false,
        available_until: product.available_until ? product.available_until : null,
      },
    }));
  }

  trackingMemberImported(member_count: number, product_count: number, products_type: any[]) {
    TrackingHelper.trackMemberImported(this.loggedUser.email, this.selectedSchool.id, member_count, product_count, products_type);
  }
}
